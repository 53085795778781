.loading.zIndex999{
  z-index: 9999;
}
.dropdown-menu.zIndex999{
  z-index: 2;
}

.organization-menu{
  .zIndex999{
    z-index: 9999;
  }
  .btn{
    padding-left: 15px;
  }
}