@import '../variable';
.user-dashboard {
    display: flex;
    height: 100vh;
    .sidebar {
        flex: 0 0 260px;
        max-width: 260px;
        background: #262c2b;
        overflow-x: hidden;
        overflow-y: auto;
        color: #fafafa;
        font-size: 14px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        .btn-success {
            padding-top: 5px;
            padding-bottom: 5px;
        }
        .profile-img {
            width: 40px;
            height: 0;
            position: relative;
            border-radius: 8px;
            padding-bottom: 40px;
            overflow: hidden;
            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .profile-name {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #ffffff;
        }
        .profile-reputation {
            color: #ffffff;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            span {
                color: #fb8081;
            }
        }
        .sidebar-item {
            padding: 22px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.25);
            &:last-child {
                border-bottom: 0;
            }
        }
        .nav-sidebar {
            list-style: none;
            padding-left: 0;
            .nav-item {
                font-family: "Poppins", sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #fafafa;
                padding: 8px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                &:hover {
                    color: #c4dd80;
                }
                .nav-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .badge {
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    background: #c4dd80;
                    color: #000000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 11px;
                }
            }
        }
        .pros-row {
            display: flex;
            flex-wrap: wrap;
            margin-left: -6px;
            margin-right: -6px;
            .pros-item {
                padding-left: 6px;
                padding-right: 6px;
                flex: 0 0 50%;
                max-width: 50%;
                margin-bottom: 12px;
                .pros-img {
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                    overflow: hidden;
                    position: relative;
                    border-radius: 16px;
                    img {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    .content-dashboard {
        width: calc(100% - 260px);
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        font-size: 14px;
        color: #757575;
        label {
            font-size: 14px;
        }
        .dahboard-header {
            padding: 16px 25px;
            position: fixed;
            top: 0;
            right: 0;
            left: 260px;
            background: #fff;
            z-index: 99;
            h1 {
                margin: 0;
                font-weight: 600;
                font-size: 28px;
                line-height: 42px;
                color: #333333;
            }
            .mui-list--inline {
                li {
                    a {
                        border: 1px solid #c4c4c4;
                        border-radius: 8px;
                        display: flex;
                        color: #757575;
                        font-size: 18px;
                        padding: 5px 8px;
                        span {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            img {
                                height: 23px;
                            }
                        }
                        &:hover {
                            background-color: #262c2b;
                            color: #fff;
                            border-color: #262c2b;
                            span {
                                img {
                                    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
                                        hue-rotate(24deg) brightness(101%) contrast(101%);
                                }
                            }
                        }
                    }
                }
            }
        }
        .content-inner {
            padding: 80px 25px;
        }
        .dashboard-footer {
            margin-left: 25px;
            margin-right: 25px;
            padding: 15px 0;
            border-top: 1px rgba(0, 0, 0, 0.12) solid;
            a {
                color: #7c7c7c;
                padding-left: 8px;
                padding-right: 8px;
            }
        }
    }
}

.progress {
    background: #f2f2f2;
    border-radius: 20px;
    overflow: hidden;
    .progress-val {
        background: #c4dd80;
        height: 14px;
        width: 27%;
        border-radius: 20px;
    }
}

.btn-location {
    padding-left: 10px;
    padding-right: 10px;
}

.map-container {
    position: relative;
    .set-map {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.06));
        background: #fff;
        text-align: center;
        padding: 20px;
        max-width: 339px;
        p {
            color: #000000;
        }
    }
}

.work-tag {
    .btn {
        margin-right: 10px;
        margin-bottom: 10px;
        position: relative;
        span {
            position: absolute;
            right: 5px;
            top: 7px;
            cursor: pointer;
        }
        &.selected {
            background: rgba(196, 221, 128, 0.4);
            border-color: rgba(196, 221, 128, 0.4);
            padding-right: 27px;
            &:hover {
                color: #333333;
                img {
                    filter: brightness(0) saturate(100%) invert(46%) sepia(14%) saturate(14%) hue-rotate(145deg)
                        brightness(94%) contrast(80%);
                }
            }
        }
    }
}
.find-work {
    max-width: 290px;
    .form-control {
        height: 32px;
        border-radius: 8px;
    }
    .btn {
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.form-add-work {
    margin-top: 10px;
    max-width: 290px;
}

.upload-color {
    background-color: $greyUpload;
    border: 1px solid $greyUploadBorder;
    color: $solidBlack;
    &:hover{
        background-color: $greyUploadBorder !important;
    }
}
.black-upload-title {
    color: $blackTitle;
}
.upload-page{
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: bold;
}