.hero-banner {
    position: relative;
    background: url(/images/hero_bg.png) no-repeat bottom center/cover;
    .hero-content {
        padding: 70px 60px;
        text-align: center;
        color: #ffffff;
        padding-bottom: 123px;
        h1 {
            font-style: normal;
            font-weight: 300;
            font-size: 60px;
            line-height: 82px;
            letter-spacing: 0.03em;
            b {
                font-weight: 700;
            }
        }
        p {
            font-weight: normal;
            font-size: 20px;
            line-height: 33px;
            text-align: center;
            letter-spacing: 0.03em;
        }
        .btn {
            background: #c4dd80;
            border-radius: 8px;
            font-size: 19px;
            text-align: center;
            padding: 15px 50px;
            display: inline-block;
            color: #262c2b;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -29px;
            &:hover {
                background: #397766;
            }
        }
    }
}

.text-ww {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.01em;
    color: #61b19b;
}

.about-section {
    padding: 100px 0;
    h2 {
        margin-top: 0;
    }
    p {
        line-height: 30px;
        margin-bottom: 30px;
    }
}

.feature-section {
    position: relative;
    padding-bottom: 50px;
    &::before {
        position: absolute;
        content: "";
        top: 400px;
        bottom: 0;
        left: 0;
        right: 0;
        background: #f5f7ee;
        z-index: -1;
    }
    .service-list {
        background: #ffffff;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
        padding: 20px 30px;
        padding-left: 70px;
        height: 100%;
        margin-left: 50px;
        position: relative;
        .service-icon {
            position: absolute;
            left: -51px;
        }
        .service-content {
            .service-title {
                font-weight: 500;
                font-size: 22px;
                line-height: 30px;
                color: #3c3c3c;
            }
            p {
                font-size: 16px;
                line-height: 26px;
                letter-spacing: 0.01em;
                color: #3c3c3c;
            }
        }
    }
}

.how-section {
    padding-top: 100px;
    padding-bottom: 100px;
    .how-item {
        background: #ffffff;
        border: 1px solid #e3e3e3;
        text-align: center;
        position: relative;
        overflow: hidden;
        padding: 40px 20px;
        h4 {
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: #131d29;
        }
        .how-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background: rgba(0, 0, 0, 0.8);
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #ffffff;
            display: flex;
            align-items: center;
            transition: all 0.3s ease-in-out;
            padding: 15px;
        }
        &:hover {
            .how-overlay {
                opacity: 1;
            }
        }
    }
}

.blog-section {
    background: #f5f7ee;
    padding: 100px 0;
    .blog-card {
        background: #ffffff;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
        .blog-content {
            padding: 15px 20px;
            h4 {
                font-family: "Roboto";
                font-weight: 600;
                font-size: 19px;
                line-height: 25px;
                color: #3c3c3c;
                margin-top: 0;
            }
            p {
                font-size: 16px;
                line-height: 26px;
                color: #3c3c3c;
            }
        }
    }
}

.our-video {
    padding-top: 100px;
    padding-bottom: 50px;
}

.video-card {
    .video-thumbnail {
        overflow: hidden;
        width: 100%;
        height: 0;
        padding-bottom: 70%;
        position: relative;
        border-radius: 10px;
        .thumbnail {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
        }
        .play-btn {
            position: absolute;
            background: #c4dd80;
            width: 50px;
            height: 50px;
            bottom: 15px;
            left: 15px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 14px;
                height: auto;
            }
        }
    }
    p {
        margin-top: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #333333;
    }
}

@media screen and (max-width: 480px) {
  .card-body {
    padding: 30px;
    overflow: auto;
    .table-row {
      .table-col{
        width: 100%;
        .table-content {
          .name{
            max-width: 50px;
          }
          .email{
            max-width: 120px;
          }
        }
      }
    }
  }
}
