@import '../variable';
.forgot-dialog{
  margin: 0px auto;
  padding: 110px 0 150px;
  width: 28rem;
  .form-input{
    .MuiTextField-root{
      margin: 40px 0;
    }
  }
}

.icon-close{
  text-align: right;
  padding: 15px;
}

.form-input{
  .success-field{
    .MuiFormHelperText-contained{
      color: $greenHelperText !important;
    }
    .MuiOutlinedInput-notchedOutline{
      border-color: $greenHelperText !important;
    }
  }
  .error-field{
    .MuiFormHelperText-contained{
      color: $redError !important;
    }
  }
}

.token-input {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  > div {
    padding-left: 10px;
    padding-right: 10px;
  }
  input {
    width: 20% !important;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    height: 50px !important;
    display: inline-block;
    margin: auto 15px !important;
    &:focus-visible {
      outline-style: solid;
      outline-color: #c4dd80;
    }
    &:first-child{
      margin-left: 0 !important;
    }
    &:last-child{
      margin-right: 0 !important;
    }
  }
}
