// header
.header {
    padding: 10px 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    .logo {
        width: 139px;
        @media screen and (max-width: 767.98px) {
            width: 100px;
        }
    }
    .nav-link {
        color: #fff;
        padding-left: 8px;
        padding-right: 8px;
        font-size: 17px;
        &.active {
            color: #c3dc80;
            text-decoration: underline;
        }
    }
}

.header-bar {
    position: fixed;
    top: 0;
    left: 90px;
    right: 0;
    display: flex;
    background: #fff;
    border-bottom: 1px solid #eceded;
    z-index: 999;

    .header-left-bar {
        flex: 0 0 90px;
        max-width: 90px;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .header-right-bar {
        width: 100%;
        display: flex;
        padding: 20px;
        border-left: 1px #eceded solid;
    }
    .btn-arrow {
        background: #e26d41;
        border-radius: 100%;
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.main-wrapper-content {
    display: flex;
    height: 100vh;

    .content-dash {
        width: 100%;
        padding: 50px;
        padding-top: 149px;
        background: url('../../../../assets/images/bg-dashboard.svg') no-repeat center center/cover #fbf2ee;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.greeting-card{
  .container{
    margin-left: 50px;
    .container-item{
      .items{
        border-right: 1px solid #ebebeb;
        display: inline-block;
        width: 30%;
        vertical-align: text-top;
        padding-left: 20px;
        ul{
          margin: 0;
          padding-left: 20px;
          li{
            text-transform: capitalize;
            margin-bottom: 20px;
            &:first-child{
              padding-left: 0;
            }
          }
        }
        &:first-child{
          padding-left: 0;
        }
      }
    }
  }
}
