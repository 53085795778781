
@font-face {
   font-family: 'Poppins';
   src: url("../../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}
$mainDark: #262C2B;
$mainWhite: #fff;
$solidBlack: #000000;
$primaryFont: 'Poppins';
$lightGreen: #C4DD80;
$borderGray: #E0E0E0;
$greyFooterBottom: #7C7C7C;
$greySecondaryText: #757575;
$greyInputLabel: #333333;
$greyTNC: #585858;
$greyBorderUserType: #D9D9D9;
$blueFBButton: #1877F2;
$whiteMenuDrawer: #FAFAFA;
$greenHelperText: #00954D;
$borderMenuDrawer: rgba(255, 255, 255, 0.16);
$redError: #d32f2f;
$greyUpload: #F5F5F5;
$greyUploadBorder: #E8E7E7;
$blackTitle: #2D3038;