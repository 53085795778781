.nav-menu {
    padding-left: 30px;
    padding-bottom: 26px;
    border-bottom: 1px #eceded solid;
    margin-bottom: 40px;
}

.sidebar {
    background: #fff;
    flex: 0 0 90px;
    max-width: 90px;
    padding-top: 31px;
    height: 100vh;
    // overflow: hidden;
    &.sidebar-open {
        flex: 0 0 284px;
        max-width: 284px;
    }
    &.sidebar-open.sidebar-close {
        max-width: 90px;
        .sidebarlabel {
            display: none;
        }
    }
    .link {
        padding: 16px;
        padding-left: 31px;
        display: flex;
        align-items: center;
        position: relative;
        .sidebarico {
            flex: 0 0 24px;
            max-width: 24px;
            position: relative;
        }
        .sidebarlabel {
            width: 100%;
            padding-left: 63px;
            position: relative;
            font-size: 15px;
            font-weight: 600;
        }
        &.active {
            img {
                filter: brightness(0) saturate(100%) invert(77%) sepia(40%) saturate(7498%) hue-rotate(332deg)
                    brightness(92%) contrast(92%);
            }
            .sidebarico{
              &::after {
                  position: absolute;
                  width: 16.38px;
                  height: 16.38px;
                  border: 4px #fff solid;
                  background-color: #e26d41;
                  content: "";
                  border-radius: 50%;
                  left: 50px;
                  top: 10px;
              }
            }
        }
    }
}
