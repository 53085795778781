@import 'homepage/scss/variable';
.MuiAppBar-colorPrimary{
  background-color: $mainDark !important;
}
.top-container{
  margin: 20px 0px;
}
// Header
.toolbard-header-ionic{
  padding: 20px 50px !important;
  .link-top{
    padding: 5px 20px;
    font-family: $primaryFont;
  }
  .link-top.join-us{
    background-color: $lightGreen;
    color: $mainDark;
    border-radius: 5px;
    margin: 0 20px;
    text-decoration: none;
  }
}


//form input

.form-input{
  .MuiTextField-root{
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .MuiInputBase-formControl{
    font-family: $primaryFont;
    margin: 15px 0;
    border-radius: 10px;
    input{
      padding: 10px 15px 12px;
      font-size: 15px;
      margin-top: -5px;
    }
    fieldset{
      legend{
        display: none;
      }
    }
    &:hover{
      fieldset{
        border-color: $lightGreen !important;
      }
    }
  }

  .MuiInputBase-formControl.Mui-focused{
    fieldset{
      border-color: $lightGreen !important;
    }
  }
  .MuiInputLabel-outlined{
    color: $greyInputLabel;
    font-family: $primaryFont;
    font-size: 20px;
    left: -13px;
    top: -7px;
  }
  .MuiFormControlLabel-label{
    font-family: $primaryFont;
  }
  .MuiCheckbox-colorPrimary.Mui-checked{
    color: $lightGreen;
  }
  .MuiButton-fullWidth{
    padding: 10px 0;
    border-radius: 10px;
  }
  .MuiFormHelperText-contained{
    margin-left: 0px;
    margin-top: -7px;
  }
}
// end form input
// Footer
footer{
  display: block;
  .footer-container{
    padding: 0 50px;
    .link-footer{
      border-top: 1px solid $borderGray;
      padding: 25px 0px 60px 0;
      a{
        font-family: $primaryFont;
        margin: 0 15px;
        text-decoration: none;
        font-size: 15px;
        line-height: 18px;
        color: $greyFooterBottom;
        &:first-child{
          margin-left: 0;
        }
      }
    }
  }
}
// end of Header
.text-underline{
  text-decoration: underline;
}
.text-center{
  text-align: center;
}
.color-secondary-text{
  color: $greySecondaryText;
}
.color-main-dark{
  color: $mainDark;
}
.color-white{
  color: $mainWhite !important;
}
.main-dark{
  background-color: $mainDark !important;
  color: $mainWhite !important;
}
.font-poppins{
  font-family: $primaryFont !important;
}
.main-white{
  background-color: $mainWhite !important;
  color: $greyInputLabel !important;
}
.cursor-pointer{
  cursor: pointer;
}
.text-uppercase{
  text-transform: uppercase;
}
.kindred-application{
  position: relative;
  .version{
    position: absolute;
    bottom: 0;
    padding: 5px;
    text-transform: capitalize;
    right: 0;
  }
}
