@import '../variable';
.MuiDrawer-paperAnchorRight{
  background: $mainDark !important;
  width: 350px;
  .close-icon{
    padding: 10px;
    text-align: right;
  }
  .drawer-menu{
    list-style: none;
    padding: 0 30px 0 20px;
    li{
      color: $whiteMenuDrawer;
      border-bottom: 1px solid $borderMenuDrawer;
      text-transform: capitalize;
      padding: 17px 0;
      cursor: pointer;
    }
  }
}
