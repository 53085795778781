// footer
footer.homepage {
    background: url(/images/footer_bg.png) no-repeat bottom center/contain;
    text-align: center;
    h2 {
        font-family: "Roboto", sans-serif;
        font-size: 55px;
        line-height: 70px;
        text-transform: uppercase;
    }
    p {
        font-size: 20px;
        line-height: 30px;
        color: #3c3c3c;
    }
    .footer-content {
        padding: 78px 0;
    }
    .copyright {
        background: #073327;
        color: #fff;
        padding: 10px 0;
    }
}
