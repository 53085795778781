*,
::after,
::before {
  box-sizing: border-box;
}

// html,
// body {
//   height: 100%;
// }

body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

a:focus {
  outline: none;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}
.mb-2 {
  margin-bottom: 20px !important;
}
.mb-3 {
  margin-bottom: 30px !important;
}
.mb-4 {
  margin-bottom: 40px !important;
}
.mb-5 {
  margin-bottom: 50px !important;
}

.mt-1 {
  margin-top: 10px !important;
}
.mt-2 {
  margin-top: 20px !important;
}
.mt-3 {
  margin-top: 30px !important;
}
.mt-4 {
  margin-top: 40px !important;
}
.mt-5 {
  margin-top: 50px !important;
}

.mr-1 {
  margin-right: 10px !important;
}
.mr-2 {
  margin-right: 20px !important;
}
.mr-3 {
  margin-right: 30px !important;
}
.mr-4 {
  margin-right: 40px !important;
}
.mr-5 {
  margin-right: 50px !important;
}

.pr-1 {
  padding-right: 10px !important;
}
.pr-2 {
  padding-right: 20px !important;
}
.pr-3 {
  padding-right: 30px !important;
}
.pr-4 {
  padding-right: 40px !important;
}
.pr-5 {
  padding-right: 50px !important;
}

.pl-1 {
  padding-left: 10px !important;
}
.pl-2 {
  padding-left: 20px !important;
}
.pl-3 {
  padding-left: 30px !important;
}
.pl-4 {
  padding-left: 40px !important;
}
.pl-5 {
  padding-left: 50px !important;
}
.pb-1 {
  padding-bottom: 10px !important;
}
.pb-2 {
  padding-bottom: 20px !important;
}
.pb-3 {
  padding-bottom: 30px !important;
}
.pb-4 {
  padding-bottom: 40px !important;
}
.pb-5 {
  padding-bottom: 50px !important;
}

.m-0 {
  margin: 0 !important;
}

.img-fluid {
  max-width: 100%;
}

.text-12 {
  font-size: 12px;
}

.text-16 {
  font-size: 16px;
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight-600 {
  font-weight: 600;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.form-control {
  display: block;
  width: 100%;
  height: 45px;
  border-radius: 4px;
  padding: 0 15px 0 15px;
  background: #f5f5f5;
  border: 1px solid #e8e7e7;
  &:not(textarea) {
    height: 45px;
  }
  &:focus-visible {
    outline-style: solid;
    outline-color: #e26d41;
  }
  &::placeholder {
    color: #c4c4c4;
  }
  &.form-control-sm {
    height: 32px;
  }
}
select.form-control {
  padding-right: 30px;
  position: relative;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  // appearance: none;
  &::before {
    position: absolute;
    content: "asd";
    background: url('../../../../assets/images/arrow-down.svg') no-repeat bottom center/contain;
    width: 30px;
    height: 30px;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}
textarea.form-control {
  padding-top: 15px;
  padding-bottom: 15px;
}
a {
  color: #61b19b;
}
a:hover {
  text-decoration: none;
}

label {
  margin-bottom: 10px;
  display: inline-block;
  font-size: 16px;
}

.w-100 {
  width: 100% !important;
}

.d-flex {
  display: flex;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.title {
  font-size: 36px;
  font-weight: bold;
  span {
    font-weight: normal;
    font-size: 20px;
  }
  @media screen and (max-width: 767.98px) {
    font-size: 23px;
    span {
      font-size: 14px;
    }
  }
}

.text-center {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Nunito", sans-serif;
}

.title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 25px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.btn {
  border-radius: 8px;
  padding: 10px 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  border: 0;
  background: transparent;
  cursor: pointer;
  .btn-icon {
    display: inline-block;
    img {
      vertical-align: sub;
    }
    &.unset {
      img {
        vertical-align: unset;
      }
    }
  }
}

.btn-outline-secondary {
  border: 1px solid #262c2b;
  border-radius: 8px;
  color: #262c2b;
  &:hover {
    background: #262c2b;
    color: #fff;
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(24deg) brightness(101%)
        contrast(101%);
    }
  }
}

.btn-outline-grey {
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  color: #757575;
  img {
    filter: brightness(0) saturate(100%) invert(52%) sepia(0%) saturate(3119%) hue-rotate(189deg) brightness(87%)
      contrast(100%);
  }
  &:hover {
    background: #262c2b;
    color: #fff;
    border-color: #262c2b;
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(24deg) brightness(101%)
        contrast(101%);
    }
  }
}
.btn {
  display: inline-block;
  border: 0;
  background: transparent;
  padding: 12px;
  cursor: pointer;
}
.btn-sm {
  padding-top: 5px;
  padding-bottom: 5px;
}
.btn-block {
  display: block;
  width: 100%;
  text-align: center;
}

.btn-success {
  background: #c4dd80;
  color: #333333;
  border: 1px #c4dd80 solid;
  &:hover {
    background: #9cb45a;
    border-color: #9cb45a;
  }
}

.btn-dark {
  background: #262c2b;
  color: #fff;
  border: 1px #262c2b solid;
  &:hover {
    background: #000;
  }
}

.btn-outline-dark {
  background: transparent;
  color: #262c2b;
  border: 1px #262c2b solid;
  &:hover {
    background: #262c2b;
    color: #fff;
  }
}

.btn-primary {
  background: #e26d41;
  border-radius: 4px;
  color: #fff;
  &:hover {
    background: #c4552c;
  }
}

.btn-outline-primary {
  border: 1px solid #e26d41;
  border-radius: 4px;
  color: #e26d41;
  &:hover {
    background: #e26d41;
    color: #fff;
  }
}
.btn-outline-primary-light {
  border: 1px solid #f1e1d8;
  border-radius: 4px;
  color: #e26d41;
  background: #fff;
  &:hover {
    background: #f1e1d8;
    color: #e26d41;
  }
}

.wrapper-wwd {
  max-width: 315px;
  margin: 50px auto;
}

.subtitle-grey {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #757575;
}

.title-2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  font-family: "Poppins", sans-serif;
}

.mmtt-2 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mmtt-3 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.upload-container {
  background: #f2f2f2;
  border: 1px solid #ffffff;
  text-align: center;
  font-size: 12px;
  color: #9a9a9a;
  position: relative;
  width: 100%;
  height: 137px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .upload-icon {
    background: url('../../../../assets/images/camera.svg') no-repeat center center/contain;
    width: 20px;
    height: 20px;
    margin: 0 auto;
  }
}

.title-small {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
}

hr {
  margin-top: 30px;
  margin-bottom: 30px;
}

.text-dark {
  color: #333333;
}

.auth-bg {
  height: 100vh;
  background: url('../../../../assets/images/bg_flash.svg') no-repeat center center/cover #fbf2ee;
}

.input-inline-group {
  position: relative;
  span {
    position: absolute;
    left: 0;
    display: inline-flex;
    top: 0;
    bottom: 0;
  }
  input {
    padding-left: 50px;
  }
  &.is-right {
    span {
      left: auto;
      right: 0;
    }
    input {
      padding-left: 15px;
      padding-right: 50px;
    }
  }
}

.sm-container {
  max-width: 375px;
  margin: auto;
  display: flex;
  height: 100%;
  align-items: center;
  .sm-container-inner {
    width: 100%;
  }
  .sm-container-content {
    background: #ffffff;
    border: 1px solid #e4ddd9;
    box-shadow: 6px 6px 30px rgba(138, 69, 39, 0.1);
    border-radius: 6px;
    overflow: hidden;
  }
}

.nav-tab {
  display: flex;
  .nav-item {
    display: block;
    width: 100%;
    text-align: center;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    padding: 13px;
    background: #fcf6f3;
    border: 1px solid #e4ddd9;
    color: #998277;
    cursor: pointer;
    &.active {
      border-color: #fff;
      background: #fff;
      color: #2d3038;
    }
  }
}

.form-content {
  padding: 20px;
}

.form-inline-group {
  position: relative;
  span {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 45px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    &.right-icon {
      left: auto;
      right: 0;
    }
  }
  .form-control {
    padding-left: 45px;
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    top: 0;
  }
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding-left: 45px;
  }
}

.check-sm {
  label {
    font-size: 11px;
    color: #787777;
    input {
      margin-top: 1px !important;
    }
  }
}

a {
  color: #e26d41;
}

.link {
  cursor: pointer;
}

.card {
  background: #ffffff;
  border: 1px solid #e4ddd9;
  box-shadow: 6px 6px 30px rgba(138, 69, 39, 0.1);
  border-radius: 6px;
}

.card-body {
  padding: 30px;
}
.card-header {
  padding: 15px 30px;
  border-bottom: 1px solid #e4ddd9;
}

.table-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  font-size: 12px;
  .table-col {
    width: 50%;
    padding-right: 15px;
    padding-left: 15px;
    .table-content {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e8e7e7;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 12px;
      > div {
        width: 100%;
      }
      &:hover{
        background-color: #ebebeb;
      }
      .name {
        font-weight: 600;
        flex: 0 0 25%;
        max-width: 25%;
        &:hover{
          color: #61b19b;
        }
      }
      .label {
        flex: 0 0 22%;
        max-width: 22%;
      }
      .email {
        flex: 0 0 45%;
        max-width: 45%;
      }
    }
  }
}

.del-btn {
  flex: 0 0 25px;
  max-width: 25px;
  .link {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #f8f0f0;
    img {
      width: 15px;
    }
  }
}

.label-j {
  display: inline-block;
  border-radius: 2px;
  padding: 5px 10px;
  img {
    width: 9px;
  }
  &.joined {
    background: #fdf2ed;
    color: #e26d41;
  }
  &.unjoined {
    color: #304950;
    background: #e8f5f8;
  }
}

.middle {
  img {
    vertical-align: middle;
  }
}

.modal {
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // background: rgba(0, 0, 0, 0.7);
  // z-index: 999;
  overflow-x: hidden;
  overflow-y: auto;
  .modal-container {
    max-width: 375px;
    margin: auto;
    min-height: calc(100% - 3.5rem);
    display: flex;
    align-items: center;
    .modal-content {
      background: #ffffff;
      border: 1px solid #e4ddd9;
      box-shadow: 6px 6px 30px rgba(138, 69, 39, 0.1);
      border-radius: 6px;
      width: 100%;
      position: relative;
      padding: 20px;
    }
  }
}

.btn-close {
  position: absolute;
  top: 8px;
  right: 9px;
  padding: 0;
}
.css-12rl710-MuiPaper-root-MuiDialog-paper {
  background: transparent !important;
  box-shadow: none !important;
  margin: 0 !important;
  padding: 32px !important;
}

.statistic {
  padding-left: 30px;
  padding-right: 30px;
}

.score {
  display: flex;
  margin-bottom: 30px;
  .score-val {
    flex: 0 0 151px;
    max-width: 151px;
    .score-numb {
      font-weight: 600;
      font-size: 60px;
      &.orange {
        color: #e26d41;
      }
      &.green {
        color: #ddeef1;
      }
    }
  }
  .progress-col {
    width: 100%;
    padding-left: 40px;
    .score-progress {
      border-radius: 4px;
      height: 100px;
      overflow: hidden;
      .score-progress-val {
        height: 100px;
        background: #e26d41;
        &.green {
          background: #ddeef1;
        }
      }
      .score-progress-val.w-0 {
        width: 1px;
      }
      .score-progress-val.w-10 {
        width: 10%;
      }
      .score-progress-val.w-20 {
        width: 20%;
      }
      .score-progress-val.w-30 {
        width: 30%;
      }
      .score-progress-val.w-40 {
        width: 40%;
      }
      .score-progress-val.w-50 {
        width: 50%;
      }
      .score-progress-val.w-60 {
        width: 60%;
      }
      .score-progress-val.w-70 {
        width: 70%;
      }
      .score-progress-val.w-80 {
        width: 80%;
      }
      .score-progress-val.w-90 {
        width: 90%;
      }
      .score-progress-val.w-100 {
        width: 100%;
      }
    }
  }
}

.daterate {
  background: #e8f5f8;
  border-radius: 2px;
  padding: 5px 10px;
  display: inline-block;
}

.rate {
  display: flex;
  justify-content: center;
  .rate-option {
    cursor: pointer;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    &:hover{
      background-color: #ebebeb;
    }
    .rate-name {
      font-weight: 600;
      font-size: 22px;
      line-height: 33px;
      color: #2d3038;
      margin-top: 30px;
    }
    &:first-child{
      .rate-name{
        margin-top: 5px;
      }
    }
  }
  .rate-option.active{
    background-color: #ebebeb;
  }
}

.menu-bar {
  display: inline-block;
  width: 30px;
  > div {
    height: 3px;
    background: #2d3038;
    border-radius: 2px;
    margin-top: 4px;
    margin-bottom: 4px;
    &:last-child {
      width: 80%;
    }
  }
}

.dropdown {
  position: relative;
  .btn {
    background: #f5f5f5;
    border: 1px solid #e8e7e7;
    border-radius: 4px;
    width: 100%;
    padding-left: 40px;
    color: #787777;
    position: relative;
    span {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 45px;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9;
      &.right-icon {
        left: auto;
        right: 0;
      }
    }
  }
  .dropdown-menu {
    position: absolute;
    background: #f5f5f5;
    border: 1px solid #e8e7e7;
    border-radius: 4px;
    width: 100%;
    padding: 10px 0;
    .dropdown-item {
      padding: 10px 15px;
      cursor: pointer;
      color: #787777;
      &:hover {
        background: #e26d41;
        color: #fff;
      }
    }
  }
}
.link-color {
  color: #e26d41;
}

.chart-wrapper {
  overflow-y: auto;
}

.chart-container {
  position: relative;
  margin: auto;
  height: 80vh;
  // width: 2900px;
}

.nav-body {
  display: flex;
  .nav-link {
    width: 50%;
    background: #FCF6F3;
    border: 1px solid #E4DDD9;
    box-sizing: border-box;
    border-radius: 6px 0px 0px 0px;
    cursor: pointer;
    padding: 14px;
    font-size: 18px;
    color: #998277;
    text-align: center;
    &.active {
      background: #fff;
      border-color: transparent;
      color: #2D3038;
    }
  }
}
